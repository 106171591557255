import * as shopTypes from "./shop.type";

const initialState = {
  categories: null,
  addToCart: null,
  cart: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${shopTypes.GET_SHOP_CATEGORIES_PENDING}`:
    case `${shopTypes.ADD_TO_CART_PENDING}`:
    case `${shopTypes.GET_CART_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${shopTypes.GET_SHOP_CATEGORIES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        categories: payload.data,
      };
    case `${shopTypes.GET_SHOP_CATEGORIES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case `${shopTypes.ADD_TO_CART_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        addToCart: payload.data,
      };
    case `${shopTypes.ADD_TO_CART_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case `${shopTypes.GET_CART_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        cart: payload.data,
      };
    case `${shopTypes.GET_CART_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case `${shopTypes.GET_BRAND_CART_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        brandCartList: payload.data,
      };

    default:
      return state;
  }
};

export default reducer;

export const GET_CART_PENDING = "GET_CART_PENDING";
export const GET_CART_FULFILLED = "GET_CART_FULFILLED";
export const GET_CART_REJECTED = "GET_CART_REJECTED";

export const ADD_TO_CART_PENDING = "ADD_TO_CART_PENDING";
export const ADD_TO_CART_FULFILLED = "ADD_TO_CART_FULFILLED";
export const ADD_TO_CART_REJECTED = "ADD_TO_CART_REJECTED";

export const REMOVE_CART_ITEM_BY_ID_PENDING = "REMOVE_CART_ITEM_BY_ID_PENDING";
export const REMOVE_CART_ITEM_BY_ID_FULFILLED =
  "REMOVE_CART_ITEM_BY_ID_FULFILLED";
export const REMOVE_CART_ITEM_BY_ID_REJECTED =
  "REMOVE_CART_ITEM_BY_ID_REJECTED";
export const REMOVE_ALL_ITEMS = "REMOVE_ALL_ITEMS";

export const REMOVE_BRAND_CART_ITEM_BY_ID_PENDING =
  "REMOVE_BRAND_CART_ITEM_BY_ID_PENDING";
export const REMOVE_BRAND_CART_ITEM_BY_ID_FULFILLED =
  "REMOVE_BRAND_CART_ITEM_BY_ID_FULFILLED";
export const REMOVE_BRAND_CART_ITEM_BY_ID_REJECTED =
  "REMOVE_BRAND_CART_ITEM_BY_ID_REJECTED";

export const REMOVE_ALL_BRAND_ITEMS_PENDING = "REMOVE_ALL_BRAND_ITEMS_PENDING";
export const REMOVE_ALL_BRAND_ITEMS_FULFILLED =
  "REMOVE_ALL_BRAND_ITEMS_FULFILLED";
export const REMOVE_ALL_BRAND_ITEMS_REJECTED =
  "REMOVE_ALL_BRAND_ITEMS_REJECTED";

export const GET_PAYMENT_MODULE_PENDING = "GET_PAYMENT_MODULE_PENDING";
export const GET_PAYMENT_MODULE_FULFILLED = "GET_PAYMENT_MODULE_FULFILLED";
export const GET_PAYMENT_MODULE_REJECTED = "GET_PAYMENT_MODULE_REJECTED";

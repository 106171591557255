import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../redux/modules";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  fallback,
  componentName,
  ...rest
}) => {
  const dispatch = useDispatch();
  const activeComponents = useSelector((store) => store.auth.activeComponents);

  // Active Component Check
  const isComponentActive = () => {
    // If componentName is not specified, always consider it active
    if (!componentName) return true;

    // componentList check
    if (!activeComponents || !activeComponents.length) return false;

    // Active component find
    return activeComponents.some(
      (item) => item.active && item.componentName === componentName
    );
  };

  React.useEffect(() => {
    if (!activeComponents) {
      dispatch(authAction.getActiveComponents());
    }
  }, [dispatch, activeComponents]);

  React.useEffect(() => {
    if (activeComponents?.length > 0) {
      console.log("activeComponents: ", activeComponents);
    }
  }, [activeComponents]);

  return (
    <Route
      {...rest}
      render={(props) => {
        // User is not authenticated, redirect to login
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // Component is not active, redirect to home
        if (!isComponentActive()) {
          console.log(
            `Component "${componentName}" is not active, redirecting to home`
          );
          return <Redirect to="/" />;
        }

        // Authenticated and component is active, render the component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;

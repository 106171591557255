import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { orderAction } from "../../redux/modules";
import {
  FaCheck,
  FaShoppingBag,
  FaBoxOpen,
  FaArrowRight,
} from "react-icons/fa";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Components
import GlobalLoader from "../../components/GlobalLoader";

const OrderSummary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    orderId: "ORD123456789",
    orderDate: "15.04.2023",
    status: "Tamamlandı",
    customer: {
      name: "Ahmet Yılmaz",
      email: "ahmet.yilmaz@email.com",
      phone: "+90 555 123 4567",
    },
  });

  const isLoading = useSelector((state) => state.order?.isLoading);
  const orderError = useSelector((state) => state.order?.error);
  const paymentToken = localStorage.getItem("paymentToken");
  const isBrandOrder = localStorage.getItem("isBrandOrder");

  useEffect(() => {
    if (paymentToken && isBrandOrder) {
      dispatch(
        orderAction.paymentFinishSession(paymentToken, isBrandOrder)
      ).then((res) => {
        if (res.isHttpSuccess) {
          localStorage.removeItem("paymentToken");
          localStorage.removeItem("isBrandOrder");
          setOrderDetails(res.data);
        }
      });
    } else {
      // history.push("/brand-cart");
    }
  }, [dispatch, history]);

  return (
    <>
      <Header />

      <main className="pb-5">
        {isLoading ? (
          <GlobalLoader />
        ) : isLoading === false ? (
          <div className="container">
            <div className="row justify-content-center py-5">
              <div className="col-lg-8">
                {/* Başarı Animasyonu Alanı */}
                <div className="text-center mb-5">
                  <div className="success-animation mb-4">
                    <div className="checkmark-circle">
                      <div
                        className="success-icon"
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                          backgroundColor: "#5cb85c",
                          margin: "0 auto",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          animation: "scale-up 0.5s ease-in-out",
                        }}
                      >
                        <FaCheck size={60} color="white" />
                      </div>
                    </div>
                  </div>
                  <h1 className="display-4 fw-bold text-success mb-3">
                    Sipariş Tamamlandı!
                  </h1>
                  <p className="lead mb-1">
                    {/* Teşekkürler, {orderDetails.customer.name}! */}
                  </p>
                  <p className="mb-4">
                    Siparişiniz başarıyla oluşturuldu ve çok yakında
                    hazırlanmaya başlanacak.
                  </p>
                  <div className="order-number mb-5">
                    {/* <p className="text-muted mb-1">Sipariş Numaranız</p> */}
                    {/* <h3 className="fw-bold">{orderDetails.orderId}</h3> */}
                  </div>
                </div>

                {/* Bilgilendirme Alanı */}
                <div className="card border-0 shadow-sm mb-5">
                  <div className="card-body p-4">
                    <div className="row g-4">
                      <div className="col-md-4">
                        <div
                          className="text-center p-3 h-100"
                          style={{
                            background: "#f8f9fa",
                            borderRadius: "10px",
                          }}
                        >
                          <FaShoppingBag
                            size={38}
                            className="text-primary mb-3"
                          />
                          <h5>Sipariş Onaylandı</h5>
                          {/* <p className="small text-muted mb-0">
                            {orderDetails.orderDate} tarihinde siparişiniz
                            başarıyla oluşturuldu.
                          </p> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="text-center p-3 h-100"
                          style={{
                            background: "#f8f9fa",
                            borderRadius: "10px",
                          }}
                        >
                          <FaBoxOpen size={38} className="text-primary mb-3" />
                          <h5>Hazırlanıyor</h5>
                          <p className="small text-muted mb-0">
                            Siparişiniz şu anda hazırlanıyor ve kargoya
                            verilecek.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="text-center p-3 h-100"
                          style={{
                            background: "#f8f9fa",
                            borderRadius: "10px",
                          }}
                        >
                          <i className="fas fa-truck fs-1 text-primary mb-3"></i>
                          <h5>Teslimat Bilgisi</h5>
                          <p className="small text-muted mb-0">
                            Tahmini teslimat süresi: 3-5 iş günü
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Sonraki Adımlar */}
                <div className="my-5 text-center">
                  <h5 className="mb-4">Sonraki Adımlar</h5>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <Link
                        to="/profile/orders"
                        className="btn btn-primary w-100 py-3 rounded-pill"
                      >
                        Siparişlerim
                      </Link>
                    </div>
                    <div className="col-sm-6">
                      <Link
                        to="/shop"
                        className="btn btn-outline-primary w-100 py-3 rounded-pill"
                      >
                        Alışverişe Devam Et <FaArrowRight className="ms-2" />
                      </Link>
                    </div>
                  </div>
                </div>

                {/* Teşekkür Mesajı */}
                <div
                  className="alert alert-light text-center p-4 border"
                  role="alert"
                >
                  <p className="mb-0">
                    <strong>Bilgi:</strong> Siparişiniz ve teslimat durumu
                    hakkında güncellemeler e-posta adresinize gönderilecektir.
                    Siparişiniz ile ilgili sorularınız için müşteri
                    hizmetlerimizle iletişime geçebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // Sadece yönlendirme için geçici yükleme göstergesi
          <div className="container text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Yükleniyor...</span>
            </div>
            <p className="mt-3">Brand Cart sayfasına yönlendiriliyorsunuz...</p>
          </div>
        )}
      </main>

      <Footer />

      <style>
        {`
          @keyframes scale-up {
            0% { transform: scale(0); opacity: 0; }
            100% { transform: scale(1); opacity: 1; }
          }
        `}
      </style>
    </>
  );
};

export default OrderSummary;

export const GET_LOGIN_USER_DETAIL_PENDING = "GET_LOGIN_USER_DETAIL_PENDING";
export const GET_LOGIN_USER_DETAIL_FULFILLED =
  "GET_LOGIN_USER_DETAIL_FULFILLED";
export const GET_LOGIN_USER_DETAIL_REJECTED = "GET_LOGIN_USER_DETAIL_REJECTED";

// ADD NEW ADDRESS FOR USER
export const SET_ADDRESS_PENDING = "SET_ADDRESS_PENDING";
export const SET_ADDRESS_FULFILLED = "SET_ADDRESS_FULFILLED";
export const SET_ADDRESS_REJECTED = "SET_ADDRESS_REJECTED";

// GET ALL ADDRESSES FOR USER
export const GET_ADDRESSES_PENDING = "GET_ADDRESSES_PENDING";
export const GET_ADDRESSES_FULFILLED = "GET_ADDRESSES_FULFILLED";
export const GET_ADDRESSES_REJECTED = "GET_ADDRESSES_REJECTED";

// REMOVE ADDRESS BY ID FOR USER
export const DELETE_ADDRESS_PENDING = "DELETE_ADDRESS_PENDING";
export const DELETE_ADDRESS_FULFILLED = "DELETE_ADDRESS_FULFILLED";
export const DELETE_ADDRESS_REJECTED = "DELETE_ADDRESS_REJECTED";

// CHANGE ADDRESS STATUS BY ID FOR USER
export const SET_ADDRESS_STATUS_PENDING = "SET_ADDRESS_STATUS_PENDING";
export const SET_ADDRESS_STATUS_FULFILLED = "SET_ADDRESS_STATUS_FULFILLED";
export const SET_ADDRESS_STATUS_REJECTED = "SET_ADDRESS_STATUS_REJECTED";

// COUNTRY - TURKIYE
export const GET_COUNTRY_PENDING = "GET_COUNTRY_PENDING";
export const GET_COUNTRY_FULFILLED = "GET_COUNTRY_FULFILLED";
export const GET_COUNTRY_REJECTED = "GET_COUNTRY_REJECTED";

// PROVINCE - CITY - ISTANBUL
export const GET_PROVINCE_PENDING = "GET_PROVINCE_PENDING";
export const GET_PROVINCE_FULFILLED = "GET_PROVINCE_FULFILLED";
export const GET_PROVINCE_REJECTED = "GET_PROVINCE_REJECTED";

// COUNTY - DISTRICT - ATASEHIR
export const GET_COUNTY_PENDING = "GET_COUNTY_PENDING";
export const GET_COUNTY_FULFILLED = "GET_COUNTY_FULFILLED";
export const GET_COUNTY_REJECTED = "GET_COUNTY_REJECTED";

// DISTRICT - BARBAROS MH.
export const GET_DISTRICT_PENDING = "GET_DISTRICT_PENDING";
export const GET_DISTRICT_FULFILLED = "GET_DISTRICT_FULFILLED";
export const GET_DISTRICT_REJECTED = "GET_DISTRICT_REJECTED";

// GET SUBJECT WRITE US
export const GET_SUBJECT_LIST_PENDING = "GET_SUBJECT_LIST_PENDING";
export const GET_SUBJECT_LIST_FULFILLED = "GET_SUBJECT_LIST_FULFILLED";
export const GET_SUBJECT_LIST_REJECTED = "GET_SUBJECT_LIST_REJECTED";

export const GET_SUBJECT_BY_ID_PENDING = "GET_SUBJECT_BY_ID_PENDING";
export const GET_SUBJECT_BY_ID_FULFILLED = "GET_SUBJECT_BY_ID_FULFILLED";
export const GET_SUBJECT_BY_ID_REJECTED = "GET_SUBJECT_BY_ID_REJECTED";

// GET PROFILE DESCRIPTION
export const GET_PROFILE_DESCRIPTION_PENDING =
  "GET_PROFILE_DESCRIPTION_PENDING";
export const GET_PROFILE_DESCRIPTION_FULFILLED =
  "GET_PROFILE_DESCRIPTION_FULFILLED";
export const GET_PROFILE_DESCRIPTION_REJECTED =
  "GET_PROFILE_DESCRIPTION_REJECTED";

// SEND MESSAGE
export const SET_SEND_MESSAGE_PENDING = "SET_SEND_MESSAGE_PENDING";
export const SET_SEND_MESSAGE_FULFILLED = "SET_SEND_MESSAGE_FULFILLED";
export const SET_SEND_MESSAGE_REJECTED = "SET_SEND_MESSAGE_REJECTED";

// SEND MESSAGE BY ID
export const SEND_MESSAGE_BY_ID_PENDING = "SEND_MESSAGE_BY_ID_PENDING";
export const SEND_MESSAGE_BY_ID_FULFILLED = "SEND_MESSAGE_BY_ID_FULFILLED";
export const SEND_MESSAGE_BY_ID_REJECTED = "SEND_MESSAGE_BY_ID_REJECTED";

// PROFILE CHANGE PASSWORD
export const SET_CHANGE_PASSWORD_PENDING = "SET_CHANGE_PASSWORD_PENDING";
export const SET_CHANGE_PASSWORD_FULFILLED = "SET_CHANGE_PASSWORD_FULFILLED";
export const SET_CHANGE_PASSWORD_REJECTED = "SET_CHANGE_PASSWORD_REJECTED";

// PROFILE IMAGE UPLOAD
export const SET_PROFILE_IMAGE_UPLOAD_PENDING =
  "SET_PROFILE_IMAGE_UPLOAD_PENDING";
export const SET_PROFILE_IMAGE_UPLOAD_FULFILLED =
  "SET_PROFILE_IMAGE_UPLOAD_FULFILLED";
export const SET_PROFILE_IMAGE_UPLOAD_REJECTED =
  "SET_PROFILE_IMAGE_UPLOAD_REJECTED";

// PROFILE - USER'S AVAILABLE SCORE
export const GET_USERS_SCORE_PENDING = "GET_USERS_SCORE_PENDING";
export const GET_USERS_SCORE_FULFILLED = "GET_USERS_SCORE_FULFILLED";
export const GET_USERS_SCORE_REJECTED = "GET_USERS_SCORE_REJECTED";

// PROFILE SCORE HISTORY - ALL
export const GET_PROFILE_SCORE_HISTORY_ALL_PENDING =
  "GET_PROFILE_SCORE_HISTORY_ALL_PENDING";
export const GET_PROFILE_SCORE_HISTORY_ALL_FULFILLED =
  "GET_PROFILE_SCORE_HISTORY_ALL_FULFILLED";
export const GET_PROFILE_SCORE_HISTORY_ALL_REJECTED =
  "GET_PROFILE_SCORE_HISTORY_ALL_REJECTED";

// PROFILE SCORE HISTORY - COLLECTED
export const GET_PROFILE_SCORE_HISTORY_COLLECTED_PENDING =
  "GET_PROFILE_SCORE_HISTORY_COLLECTED_PENDING";
export const GET_PROFILE_SCORE_HISTORY_COLLECTED_FULFILLED =
  "GET_PROFILE_SCORE_HISTORY_COLLECTED_FULFILLED";
export const GET_PROFILE_SCORE_HISTORY_COLLECTED_REJECTED =
  "GET_PROFILE_SCORE_HISTORY_COLLECTED_REJECTED";

// PROFILE SCORE HISTORY - SPENT
export const GET_PROFILE_SCORE_HISTORY_SPENT_PENDING =
  "GET_PROFILE_SCORE_HISTORY_SPENT_PENDING";
export const GET_PROFILE_SCORE_HISTORY_SPENT_FULFILLED =
  "GET_PROFILE_SCORE_HISTORY_SPENT_FULFILLED";
export const GET_PROFILE_SCORE_HISTORY_SPENT_REJECTED =
  "GET_PROFILE_SCORE_HISTORY_SPENT_REJECTED";

export const GET_PROFILE_USER_IMAGE_PENDING = "GET_PROFILE_USER_IMAGE_PENDING";
export const GET_PROFILE_USER_IMAGE_FULFILLED =
  "GET_PROFILE_USER_IMAGE_FULFILLED";
export const GET_PROFILE_USER_IMAGE_REJECTED =
  "GET_PROFILE_USER_IMAGE_REJECTED";

export const REMOVE_IMAGE_PENDING = "REMOVE_IMAGE_PENDING";
export const REMOVE_IMAGE_FULFILLED = "REMOVE_IMAGE_FULFILLED";
export const REMOVE_IMAGE_REJECTED = "REMOVE_IMAGE_REJECTED";

export const IMAGE_UPLOAD_PENDING = "IMAGE_UPLOAD_PENDING";
export const IMAGE_UPLOAD_FULFILLED = "IMAGE_UPLOAD_FULFILLED";
export const IMAGE_UPLOAD_REJECTED = "IMAGE_UPLOAD_REJECTED";

export const GET_ORDER_LIST_PENDING = "GET_ORDER_LIST_PENDING";
export const GET_ORDER_LIST_FULFILLED = "GET_ORDER_LIST_FULFILLED";
export const GET_ORDER_LIST_REJECTED = "GET_ORDER_LIST_REJECTED";

export const GET_BRAND_ORDER_LIST_PENDING = "GET_BRAND_ORDER_LIST_PENDING";
export const GET_BRAND_ORDER_LIST_FULFILLED = "GET_BRAND_ORDER_LIST_FULFILLED";
export const GET_BRAND_ORDER_LIST_REJECTED = "GET_BRAND_ORDER_LIST_REJECTED";

export const GET_TENANT_MENU_PENDING = "GET_TENANT_MENU_PENDING";
export const GET_TENANT_MENU_FULFILLED = "GET_TENANT_MENU_FULFILLED";
export const GET_TENANT_MENU_REJECTED = "GET_TENANT_MENU_REJECTED";

export const GET_MONTHLY_PERFORMANCE_PENDING =
  "GET_MONTHLY_PERFORMANCE_PENDING";
export const GET_MONTHLY_PERFORMANCE_FULFILLED =
  "GET_MONTHLY_PERFORMANCE_FULFILLED";
export const GET_MONTHLY_PERFORMANCE_REJECTED =
  "GET_MONTHLY_PERFORMANCE_REJECTED";

export const GET_MONTHLY_INCOME_PERFORMANCE_PENDING =
  "GET_MONTHLY_INCOME_PERFORMANCE_PENDING";
export const GET_MONTHLY_INCOME_PERFORMANCE_FULFILLED =
  "GET_MONTHLY_INCOME_PERFORMANCE_FULFILLED";
export const GET_MONTHLY_INCOME_PERFORMANCE_REJECTED =
  "GET_MONTHLY_INCOME_PERFORMANCE_REJECTED";

export const GET_PRODUCT_FOUND_PERFORMANCE_PENDING =
  "GET_PRODUCT_FOUND_PERFORMANCE_PENDING";
export const GET_PRODUCT_FOUND_PERFORMANCE_FULFILLED =
  "GET_PRODUCT_FOUND_PERFORMANCE_FULFILLED";
export const GET_PRODUCT_FOUND_PERFORMANCE_REJECTED =
  "GET_PRODUCT_FOUND_PERFORMANCE_REJECTED";

export const GET_PERCENTAGE_PERFORMANCE_PENDING =
  "GET_PERCENTAGE_PERFORMANCE_PENDING";
export const GET_PERCENTAGE_PERFORMANCE_FULFILLED =
  "GET_PERCENTAGE_PERFORMANCE_FULFILLED";
export const GET_PERCENTAGE_PERFORMANCE_REJECTED =
  "GET_PERCENTAGE_PERFORMANCE_REJECTED";

export const GET_FOCUS_INFO_PERFORMANCE_PENDING =
  "GET_FOCUS_INFO_PERFORMANCE_PENDING";
export const GET_FOCUS_INFO_PERFORMANCE_FULFILLED =
  "GET_FOCUS_INFO_PERFORMANCE_FULFILLED";
export const GET_FOCUS_INFO_PERFORMANCE_REJECTED =
  "GET_FOCUS_INFO_PERFORMANCE_REJECTED";

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, shopAction, brandAction } from "../../../redux/modules";

// Toastify
import showErrMessage from "../../../components/ShowErrMessage";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

// Styles
import "./styles.scss";
import showProductMessage from "../../../components/showProductMessage";

const HomeProduct = () => {
  const dispatch = useDispatch();
  const brandList = useSelector((store) => store.home.brandList);
  const productList = useSelector((store) => store.home.productList);
  const cartData = useSelector((store) => store.cart?.cartData);
  const [productListMostSeller, setProductListMostSeller] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [filteredBrandStyle, setFilteredBrandStyle] = useState([]);

  const filteredBrandList = useCallback(() => {
    if (brandList && brandList.content) {
      return brandList.content.filter((item) => item.brandStyleSize > 0);
    }
    return [];
  }, [brandList]);

  useEffect(() => {
    if (selectedBrand?.id) {
      dispatch(
        brandAction.getBrandStyleList(0, 2000, "name,asc", selectedBrand?.id)
      ).then((res) => {
        if (res.isHttpSuccess) {
          setFilteredBrandStyle(res.data);

          // Brand Category List By Id Filter
          dispatch(brandAction.getBrandCategoryList(selectedBrand?.id));
        }
      });
    }
  }, [selectedBrand, dispatch]);

  useEffect(() => {
    dispatch(homeAction.getBrandList());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (cartData?.length > 0) {
      if (
        productList &&
        productList.content &&
        productList.content.length > 0
      ) {
        for (const prod of productList.content) {
          for (const item of cartData?.cartProductDTOList) {
            if (prod.id === item?.product?.id) {
              prod.productCartCount = item?.productCount;
            }
          }
        }

        setProductListMostSeller(productList);
      }
    }
    return () => {};
  }, [cartData, productList]);

  useEffect(() => {
    if (productList) {
      setProductListMostSeller(productList);
    }
    return () => {};
  }, [productList]);

  useEffect(() => {
    dispatch(homeAction.getProductList("sold"));
    return () => {};
  }, [dispatch]);

  const addToCart = (e) => {
    e.preventDefault();

    const children = e.target.closest("div").children;
    var activeId = parseInt(e.target.closest("a").getAttribute("data-id"));
    var productCartCount = parseInt(
      e.target.closest("a").getAttribute("data-count")
    );

    if (children) {
      for (const i of children) {
        let clickedItem = e.target.closest("a");
        const addedId = i.getAttribute("data-added");
        if (parseInt(addedId) === activeId) {
          i.classList.remove("d-none");
          clickedItem.classList.add("d-none");

          setTimeout(() => {
            i.classList.add("d-none");
            clickedItem.classList.remove("d-none");
          }, 3500);
        }
      }
    }

    productCartCount = productCartCount + 1;

    dispatch(homeAction.addToCart(activeId, productCartCount))
      .then((response) => {
        if (response.isHttpSuccess) {
          showProductMessage(`Ürün sepete eklendi!`);
          dispatch(shopAction.getCart());
          dispatch(homeAction.getProductList("sold"));
        }
      })
      .catch((err) => {
        if (err.isHttpError) {
          showErrMessage(err.message);
        }
      });
  };

  const handleBrandSelect = (brand) => {
    console.log("brand11: ", brand);
    setSelectedBrand(brand);
  };

  useEffect(() => {
    if (brandList && brandList.content && brandList.content.length > 0) {
      const firstValidBrand = brandList.content.find(
        (brand) => brand.brandStyleSize > 0
      );
      if (firstValidBrand) {
        setSelectedBrand(firstValidBrand);
      }
    }
  }, [brandList]);

  return (
    <section className="sliderBrandss mb-5 mt-5">
      <div className="container mb-5">
        <h1 className="text-center mb-0">Ürünlerimiz</h1>
      </div>

      <div className="container-xxl container-scrollable mb-sm-5 mb-4">
        <ul className="nav nav-pills justify-content-sm-center">
          {filteredBrandList()
            ? filteredBrandList()?.map((item, i) => (
                <li className="nav-item" key={item.id}>
                  <a
                    href="#"
                    className={`nav-link${i === 0 ? " active" : ""}`}
                    data-bs-toggle="pill"
                    onClick={() => handleBrandSelect(item)}
                    data-bs-target={`#tabBrands${item.id}`}
                  >
                    <span className="h5 mb-0">{item.name}</span>
                  </a>
                </li>
              ))
            : null}
          <li className="nav-item">
            <Link to="/brands" className="nav-link">
              <span className="h5 mb-0">Tümü</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="container">
        <div className="row">
          {filteredBrandStyle &&
          filteredBrandStyle.content &&
          filteredBrandStyle.content.length > 0 ? (
            filteredBrandStyle.content.slice(0, 6).map((item) => (
              <div className="col-lg-4 col-md-6 mb-4" key={item.id}>
                <Link
                  to={`/brand-product-detail/${item.brandSubCategoryId}/${item.id}`}
                  className="item d-flex flex-sm-row flex-column align-items-sm-center align-items-start pb-3 h-100 border-bottom d-block text-decoration-none"
                >
                  <img
                    src={
                      item?.brandStyleContentDTOList
                        ? item?.brandStyleContentDTOList[0].baseAddress
                        : "https://via.placeholder.com/60"
                    }
                    alt=""
                    className="img-contain"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div className="mt-sm-0 mt-3 ms-sm-3 ms-0 overflow-hidden">
                    <h6 className="fw-normal small mb-1 text-gray-darkest text-truncate">
                      {item.name}
                    </h6>
                    <span className="small text-blue">{item.price} ₺</span>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="col-12 text-center">
              <p>Bu markaya ait ürün bulunamadı.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default HomeProduct;

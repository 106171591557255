/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesAction, profileAction } from "../../redux/modules";

// Components
import showErrMessage from "../../components/ShowErrMessage";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// Styles
import "./styles.scss";

// Images
import BgDots from "../../assets/images/games/bg-pattern.png";
import Girls from "../../assets/images/games/lucky-wheel/girls.png";
import Point from "../../assets/images/games/who-wants-score/point.png";
import PointRed from "../../assets/images/games/who-wants-score/point-red.png";
import Gift from "../../assets/images/games/who-wants-score/gift.png";
import Box from "../../assets/images/games/who-wants-score/box.png";

// CountdownCircleTimer
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const GameWhoWantsScore = () => {
  const dispatch = useDispatch();

  // Game WWS
  const wws = useSelector((store) => store.games.wws);

  // Game WWS Control
  const wwsControl = useSelector((store) => store.games.wwsControl);

  // Game WWS wwsStart
  const wwsStart = useSelector((store) => store.games.wwsStart);

  // Game WWS Bonus
  const wwsBonus = useSelector(store => store.games.wwsBonus);

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const [btnDisabled, setBtnDisabled] = useState(false);

  // Start Game
  const [gameStarted, setGameStarted] = useState(false);

  // Game is Finish?
  const [isFinish, setIsFinish] = useState(false);

  // Response Message
  const [responseMessage, setResponseMessage] = useState("");
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  // Timer Defaults
  const [timerColors] = useState(["#76e17b", "#F7B801"]);
  const [timerColorsTime, setTimerColorsTime] = useState([90, 0]);
  const [timerDuration, setTimerDuration] = useState(0);

  const [point, setPoint] = useState(null);
  const [callFix, setCallFix] = useState(false);

  // CountdownTimer When Complete
  const handleTimerComplete = () => {
    dispatch(gamesAction.wwsSaveAnswer(wwsStart?.id))
      .then((res) => {
        if (res.isHttpSuccess) {
          setIsFinish(true);
          setIsTimeout(true);
          setTimerDuration(0);
          setResponseMessage(res.data?.responseText);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        if (err.isHttpError) {
          setIsFinish(true);
          setIsTimeout(true);
          setTimerDuration(0);
          setResponseMessage("Zamanında cevaplayamadınız!");
        }
      });
  };

  // Begin Game
  const handleBeginGame = () => {
    dispatch(gamesAction.getWwsStart())
      .then((res) => {
        if (res.isHttpSuccess && res.data) {
          setGameStarted(true);
          dispatch(gamesAction.getWwsControl());
        }
      })
      .catch((err) => {
        console.error("Oyun başlatma hatası:", err);
      });
  };

  const handleWwsSaveAnswer = (e) => {
    e.preventDefault();
    
    if (selectedAnswer) {
      setBtnDisabled(true);
      dispatch(gamesAction.wwsSaveAnswer(selectedAnswer))
      .then((response) => {
        // Success
        if (response.isHttpSuccess) {
          let message = response?.data?.responseText;
          message = message.replace("{point}", response?.data?.score);
          setResponseMessage(message);
          setTimerDuration(0);
          
          if (response?.data?.score) {
            setPoint(response?.data?.score);
          }

          if (response?.data?.answerTrue === true) {
            setIsCorrectAnswer(true);
            setIsFinish(true);
            setTimeout(() => {
              dispatch(profileAction.getLoginUserDetail());
            }, 2000);
          } else if (response?.data?.answerFalse === true) {
            setIsFinish(true);
            setIsCorrectAnswer(false);
          } else if (response?.data?.answerTimeOut === true) {
            setIsTimeout(true);
            setIsFinish(true);
          }
        }
      })
      .catch((err) => {
        // Error
        if (err.isHttpError) {
          console.log(err.isHttpError);
        }
      });
    } else {
      showErrMessage("Lütfen bir seçenek seçiniz!");
    }
  };

  // GET WWS
  useEffect(() => {
    dispatch(gamesAction.getWws());
    dispatch(gamesAction.getWwsControl());

    return () => {};
  }, [dispatch]);

  // Defaults
  useEffect(() => {
    if (wwsControl) {
      // Timer
      setTimerDuration(wwsControl.remainTime);

      // Timer Colors
      setTimerColorsTime([wwsControl.remainTime, 0]);
      setGameStarted(
        wwsControl?.gameConditionStatus === "CONTINUES" ? true : false
      );

      if (
        wwsControl?.gameConditionStatus === "CONTINUES" &&
        wwsControl.remainTime > 0 && 
        !callFix
      ) {
        setCallFix(true);
        handleBeginGame();
      }
    }
  }, [wwsControl]);

  // GET WWS BONUS
  useEffect(() => {
    if (wws?.id) {
      dispatch(gamesAction.getWwsBonus(wws.id));
    }
  }, [wws]);

  return (
    <>
      <main className="main-container game-who-wants-score">
        <Header />
        
        <section
          className="games-page page-container wheel-page who-wants-score-page"
          id="backgroundPattern"
          style={{ backgroundImage: wws?.backgroundPattern }}
        >
          <div
            className="games-page-introduction"
            style={{ backgroundImage: `url(${BgDots})` }}
            id="backgroundImage"
          >
            <div
              className={
                gameStarted === true && isFinish === false
                  ? "d-block game-timer"
                  : "d-none game-timer"
              }
            >
              <h3 className="text-center">Kalan Süre</h3>
              <div className="game-countdown text-center ml-auto mr-auto">
                {timerColors && timerColorsTime && wws && wws.remainTime &&
                timerDuration && timerDuration > 0 && (
                  <CountdownCircleTimer
                    isPlaying
                    duration={wws?.remainTime}
                    initialRemainingTime={timerDuration}
                    colors={timerColors}
                    colorsTime={timerColorsTime}
                    size={80}
                    strokeWidth={8}
                    onComplete={() => handleTimerComplete()}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                )}
              </div>
            </div>

            <Container>
              {gameStarted === false && isFinish === false && (
                <Row className="justify-content-md-center text-center text-white">
                  {wws && (
                    <Col xs="12">
                      <div className="d-flex flex-column justify-content-center align-items-center wrapper-wp-start">
                        <div className="games-page-circle mb-md-5 mb-3">
                          <img src={wws?.icon} alt={wws?.title} />
                        </div>
                        <h2 className="mb-3 mb-md-4">{wws?.title}</h2>
                        <p className="mb-3">{wws?.description}</p>

                        <h2 className="mb-3 mb-md-4 mt-3 mt-md-4">Oyun Durumu</h2>

                        {wwsBonus?.totalPoint >= 0 &&   
                          <div>Kazanılan Toplam Puan: {wwsBonus?.totalPoint}</div>
                        }

                        <div className="wrapper-point-list">

                          {wwsBonus?.weeks?.length > 0 && 
                            wwsBonus?.weeks?.map((week) => (
                              <div className="wrapper-point" key={week?.questionId}>
                                {week?.isCorrectAnswer === false ?
                                  <img src={PointRed} className="point active" style={{ filter: `grayscale(0.3)` }} alt="Point" /> : 
                                  <img src={Point} className={week?.isCorrectAnswer === true ? "point active" : "point"} alt="Point" />
                                }
                                
                                <div className="point-area">
                                  <span>{week?.title}</span>
                                  <span className="point-text">{week?.score}</span>
                                  <span>Puan</span>
                                </div>
                              </div>
                            ))
                          }

                          {wwsBonus?.bonusScore && 
                            <div className="wrapper-point">
                              {wwsBonus.isBonus === "NOTWON" &&
                                <img src={PointRed} className="point active"  style={{ filter: `grayscale(0.3)` }} alt="Point" />
                              }

                              {wwsBonus.isBonus === "WON" && 
                                <img src={Point} className="point active" alt="Point" />
                              }

                              {wwsBonus.isBonus === "EMPTY" && 
                                <img src={Point} className="point" alt="Point" />
                              }
                              
                              <div className="point-area">
                                <span>Hediye</span>
                                <span className="point-text">+{wwsBonus?.bonusScore}</span>
                                <span>Puan</span>
                              </div>

                              <img src={Gift} className="gift" alt="Gift" />
                              <img src={Box} className="box" alt="Box" />
                            </div>
                          }
                        </div>

                        {wwsControl?.gameConditionStatus !==
                            "NOT_PLAYED" ? (
                            <div className="mt-3">
                              {(() => {
                                switch (wwsControl?.gameConditionStatus) {
                                  case "PLAYED":
                                    return "Daha önceden yarışmaya katıldınız. Lütfen daha sonra tekrar deneyiniz.";
                                  case "CONTINUES":
                                    return "Yarışma devam ediyor. Başarılar!";
                                  case "NO_GAME":
                                    return "Şu anda aktif oyun bulunmamaktadır. Lütfen daha sonra tekrar deneyiniz.";
                                  case "TIMEOUT":
                                    return "Cevaplama süresi doldu. Bir sonraki yarışmada görüşmek üzere!";
                                  default:
                                    return "Durum belirlenemedi. Lütfen daha sonra tekrar deneyiniz.";
                                }
                              })()}
                            </div>
                          ) : (
                            <Button
                              onClick={() => handleBeginGame()}
                              className="btn btn-success mt-0 mb-0 text-white"
                            >
                              {wws?.buttonText}
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              )}

              {gameStarted === true && isFinish === false && (
                <div className="wrapper-question-start">
                  {/* Question */}
                  <div className="wrapper-question">
                    <Form onSubmit={handleWwsSaveAnswer}>
                      <Form.Group as={Row} className="text-center">
                        {wwsStart?.whoWantsScoreQuestionDTOList?.length > 0 && (
                          <Form.Label
                            as="legend"
                            column
                            sm={12}
                            style={{
                              fontSize: "24px",
                              padding: "0",
                              marginBottom: "20px",
                            }}
                          >
                            {
                              wwsStart?.whoWantsScoreQuestionDTOList[0]?.description
                            }
                          </Form.Label>
                        )}

                        <Col sm={12} className="mb-3">
                          <div className="d-flex flex-row justify-content-center">
                            {wwsStart?.whoWantsScoreQuestionDTOList?.length > 0 && 
                            wwsStart?.whoWantsScoreQuestionDTOList[0]?.whoWantsScoreChoiceDTOList?.length > 0 && 
                            wwsStart?.whoWantsScoreQuestionDTOList[0]?.whoWantsScoreChoiceDTOList?.map(
                                (answer, aIndex) => {
                                  return (
                                    <Form.Check
                                      key={aIndex}
                                      type="radio"
                                      label={answer.description}
                                      name={"answer"}
                                      value={answer.id}
                                      id={answer.id}
                                      onClick={() =>
                                        setSelectedAnswer(
                                          answer.id
                                        )
                                      }
                                    />
                                  );
                                }
                              )}
                          </div>

                          <Button
                            variant="primary"
                            className="btn btn-success text-white mt-4"
                            type="submit"
                            disabled={btnDisabled}
                          >
                            Cevapla
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                  {/* End Question && Answers */}
                </div>
              )}

              {isFinish === true && (
                <Row className="justify-content-md-center text-center">
                  {isTimeout === true ? (
                    <>
                      <h1>Zamanında Cevaplayamadınız!</h1>
                      {responseMessage && (
                        <p>{responseMessage}</p>
                      )}
                      <div className="wrapper-reward active">
                        <img src={Girls} alt="" />
                        <h4>0</h4>
                      </div>
                    </>
                  ) : (
                    <>
                      {isCorrectAnswer === true ? (
                        <Col
                          xs={12}
                          className="text-center find-mate-win active"
                        >
                          <h1>Tebrikler Kazandınız!</h1>
                          <p className="!m-0">
                            {responseMessage}
                          </p>

                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            <h4>{point}</h4>
                          </div>
                        </Col>
                      ) : (
                        <Col
                          xs={12}
                          className="text-center find-mate-win active"
                        >
                          <h1>Kaybettiniz!</h1>
                          <p className="!m-0">
                            {responseMessage}
                          </p>

                          <div className="wrapper-reward active">
                            <img src={Girls} alt="" />
                            <h4>0</h4>
                          </div>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              )}

            </Container>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default GameWhoWantsScore;

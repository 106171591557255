/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  userAction,
  shopAction,
  homeAction,
  productAction,
} from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Components
import TitleBackground from "../../components/TitleBackground";
import Tooltip from "../../components/Tooltip";
import Modal from "../../components/Modal";

// jQuery
import $ from "jquery";

// react-icons
import {
  FaCommentDots,
  FaHeart,
  FaMinus,
  FaPlus,
  FaStar,
} from "react-icons/fa";

// Format Date
import moment from "moment";

// import Swiper core and required modules
import { Navigation, FreeMode, Thumbs } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

// Styles
import "./styles.scss";

// Toastify
import showErrMessage from "../../components/ShowErrMessage";
import showProductMessage from "../../components/showProductMessage";

const ShopDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const cartData = useSelector((store) => store.cart.cartData);
  const productList = useSelector((store) => store.home.productList);
  const productData = useSelector((store) => store.product.productData);
  const profileDetail = useSelector((store) => store.profile.profileDetail);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [productCount, setProductCount] = useState(1);
  const [selectedRate, setSelectedRate] = useState(0);
  const [text, setText] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Scroll Top Code
    $("html, body").animate({ scrollTop: 0 });

    dispatch(productAction.getProductById(id));
    dispatch(productAction.getProductRateById(id));

    return () => {};
  }, [dispatch, id]);

  useEffect(() => {
    if (productData) {
      dispatch(homeAction.getProductList("updateDate", "DESC", 0, 5, 0));
    }

    return () => {};
  }, [dispatch, productData]);

  const addToCart = (e, productId) => {
    e.preventDefault();

    let count = parseInt(productCount);

    if (cartData?.length > 0) {
      for (const item of cartData?.cartProductDTOList) {
        if (item?.product?.id === productId) {
          count += parseInt(item?.productCount);
        }
      }
    }

    // Ürün adedinin mevcut stok miktarını aşıp aşmadığını kontrol eder
    if (count > productData.stock) {
      showErrMessage(
        `Stok adedi ${productData.stock}'tir. Sadece ${productData.stock} ürün ekleyebilirsiniz.`
      );
      return;
    }

    dispatch(shopAction.addToCart(productId, count))
      .then((e) => {
        if (e.isHttpSuccess) {
          showProductMessage(`Ürün sepete eklendi!`);
        }
      })
      .catch((err) => {
        if (err.isHttpError) {
          console.log("err: ", err);
          showErrMessage(err?.error?.response?.data?.errors[0]?.message);
        }
      });
  };

  const handlePlus = (e, stock) => {
    e.preventDefault();

    if (productCount + 1 <= stock) {
      setProductCount(productCount + 1);
    }
  };

  const handleMinus = (e) => {
    e.preventDefault();
    if (productCount - 1 >= 1) {
      setProductCount(productCount - 1);
    }
  };

  const handleAddFavourite = (e, productId) => {
    e.preventDefault();
    dispatch(productAction.setFavouriteProductById(productId)).then((e) => {
      if (e.isHttpSuccess) {
        dispatch(productAction.getProductById(id));
      }
    });
  };

  const handleRemoveFavourite = (e, productId) => {
    e.preventDefault();
    dispatch(productAction.removeFavouriteProductById(productId)).then((e) => {
      if (e.isHttpSuccess) {
        dispatch(productAction.getProductById(id));
      }
    });
  };

  const handleOpenEvaluateModal = (e) => {
    e.preventDefault();

    setShowModal(true);
  };

  const handleCloseEvaluateModal = (e) => {
    if (e) {
      e.preventDefault();
    }

    setShowModal(false);
  };

  const handleEvaluate = (e) => {
    e.preventDefault();
    dispatch(
      productAction.setProductRateById(productData.id, selectedRate, text)
    )
      .then((e) => {
        if (e.isHttpSuccess) {
          console.log("değerlendirme ve yorum yapıldı.");
          setShowModal(false);
          setValidationError(false);
          setErrorMessage("");
        }
      })
      .catch((e) => {
        if (e.isHttpError) {
          setValidationError(true);
          setErrorMessage(e.message);
        }
      });
  };

  return (
    <>
      <Header />

      <main>
        <section
          className="position-relative pt-sm-5 pt-3 mb-sm-10 mb-5"
          data-title-background="pt"
        >
          <TitleBackground />
          <div className="container">
            <div
              className="d-lg-flex d-none text-white small mb-4 opacity-50"
              data-title-background
            >
              <NavLink to="/" className="text-white text-decoration-none me-3">
                Anasayfa
              </NavLink>
              <span className="me-3">»</span>
              <NavLink
                to="/shop"
                className="text-white text-decoration-none me-3"
              >
                Dükkan
              </NavLink>
              {productData?.categoryName && (
                <>
                  <span className="me-3">»</span>{" "}
                  <span className="me-3">{productData.categoryName}</span>
                </>
              )}

              {productData?.subCategoryName && (
                <>
                  <span className="me-3">»</span>{" "}
                  <span className="me-3">{productData.subCategoryName}</span>
                </>
              )}
            </div>

            <div className="row gy-5">
              <div className="col-lg-5">
                {/* Main Slider */}
                <div className="position-relative bg-white rounded-3 overflow-hidden mb-2">
                  <Swiper
                    spaceBetween={0}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper-product-slider rounded-3"
                  >
                    {productData &&
                    productData.productContentUrlList &&
                    productData.productContentUrlList.length > 0 ? (
                      productData.productContentUrlList.map((item) => {
                        return (
                          <SwiperSlide key={item.id}>
                            {item.baseAddress && (
                              <a
                                href={item.baseAddress}
                                className="d-block"
                                data-fancybox
                              >
                                <img
                                  src={item.baseAddress}
                                  className="w-100 img-cover"
                                  alt=""
                                />
                              </a>
                            )}
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <SwiperSlide>
                        <div style={{ background: "#000", width: "100%" }}>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/img/logo.svg"
                            }
                            alt=""
                            className="w-100 opacity-50"
                            style={{
                              width: "310px",
                              height: "100%",
                              objectFit: "scale-down",
                            }}
                          />
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>
                  {/* End Main Slider */}

                  {/* Thumnnail Slider */}
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper-product-thumbs-slider"
                  >
                    {productData &&
                    productData.productContentUrlList &&
                    productData.productContentUrlList.length > 0 ? (
                      productData.productContentUrlList.map((item) => {
                        return (
                          <SwiperSlide key={item.id}>
                            {item.baseAddress ? (
                              <img
                                src={item.baseAddress}
                                className="rounded-3 border-blue-lighter"
                                style={{
                                  height: "100px",
                                  objectFit: "contain",
                                }}
                                alt=""
                              />
                            ) : (
                              <div
                                style={{ height: "200px", background: "#000" }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/logo.svg"
                                  }
                                  alt=""
                                  className="w-100 opacity-50"
                                  style={{
                                    width: "310px",
                                    height: "200px",
                                    objectFit: "scale-down",
                                  }}
                                />
                              </div>
                            )}
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <SwiperSlide>
                        <div style={{ height: "200px", background: "#000" }}>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/img/logo.svg"
                            }
                            alt=""
                            className="w-100 opacity-50"
                            style={{
                              width: "310px",
                              height: "200px",
                              objectFit: "scale-down",
                            }}
                          />
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>
                  {/* End Thumnnail Slider */}

                  {productData?.topIcon && productData?.topIconText && (
                    <span className="rounded-pill bg-orange text-white small position-absolute z-index-top top-0 start-0 px-3 py-1 m-sm-3 m-2">
                      {productData?.topIconText}
                    </span>
                  )}
                </div>
              </div>

              {/* Product Content */}
              {productData && (
                <div className="col-lg-5 offset-lg-1 offset-0">
                  {productData.name && (
                    <h1
                      className="mb-5 text-white text-white-lg"
                      data-title-background
                    >
                      {productData.name}
                    </h1>
                  )}

                  <div className="pt-lg-5 pt-0">
                    <div className="mb-5">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          {productData.stock && (
                            <span className="text-blue small mb-2 d-block">
                              {productData.stock} Stok
                            </span>
                          )}

                          {productData.score && (
                            <span className="h2 text-green mb-4 d-block fw-bold">
                              {productData.score} puan
                            </span>
                          )}

                          <div className="h4 text-gray mb-2 d-block fw-bold">
                            {productData.rate >= 1 ? (
                              <FaStar className="text-yellow" />
                            ) : (
                              <FaStar />
                            )}

                            {productData.rate >= 2 ? (
                              <FaStar className="text-yellow" />
                            ) : (
                              <FaStar />
                            )}

                            {productData.rate >= 3 ? (
                              <FaStar className="text-yellow" />
                            ) : (
                              <FaStar />
                            )}

                            {productData.rate >= 4 ? (
                              <FaStar className="text-yellow" />
                            ) : (
                              <FaStar />
                            )}

                            {productData.rate === 5 ? (
                              <FaStar className="text-yellow" />
                            ) : (
                              <FaStar />
                            )}
                          </div>
                          <span className="text-gray small mb-2 d-block">
                            {productData.countOfRate} Değerlendirme
                          </span>

                          <div>
                            {productData.favouriteStatus ? (
                              <a
                                href="#"
                                className="text-gray"
                                onClick={(e) =>
                                  handleRemoveFavourite(e, productData.id)
                                }
                              >
                                <FaHeart
                                  style={{ fill: "red", color: "red" }}
                                />
                                <span
                                  className="d-inline-block"
                                  style={{ marginLeft: "5px" }}
                                >
                                  Vazgeç
                                </span>
                              </a>
                            ) : (
                              <a
                                href="#"
                                className="text-gray text-decoration-none"
                                onClick={(e) =>
                                  handleAddFavourite(e, productData.id)
                                }
                              >
                                <FaHeart />
                                <span
                                  className="d-inline-block"
                                  style={{ marginLeft: "5px" }}
                                >
                                  Beğen
                                </span>
                              </a>
                            )}

                            {productData.canComment && (
                              <a
                                href="#"
                                className="text-gray text-decoration-none mx-3"
                                onClick={(e) => handleOpenEvaluateModal(e)}
                              >
                                <FaCommentDots />
                                <span
                                  className="d-inline-block"
                                  style={{ marginLeft: "5px" }}
                                >
                                  Değerlendir
                                </span>
                              </a>
                            )}
                          </div>

                          <div className="mt-3">
                            <a href="#" onClick={(e) => handleMinus(e)}>
                              <FaMinus
                                style={{ fill: "#000", color: "#000" }}
                              />
                            </a>

                            <input
                              type="text"
                              value={productCount}
                              maxLength={3}
                              className="counter"
                              onChange={(e) => {
                                const value = e.target.value;
                                const parsedValue = parseInt(value, 10);

                                if (value === "" || isNaN(parsedValue)) {
                                  setProductCount(value); // Geçici olarak boş değeri veya geçersiz sayıyı ayarla
                                } else if (parsedValue > productData.stock) {
                                  setProductCount(productData.stock); // Maksimum stok değerinden fazla olamaz
                                } else {
                                  setProductCount(parsedValue); // Geçerli sayıyı ayarla
                                }
                              }}
                              onBlur={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (isNaN(value) || value < 1) {
                                  setProductCount(1); // Odak kaybı durumunda geçerli olmayan değeri 1 olarak ayarla
                                } else if (value > productData.stock) {
                                  setProductCount(productData.stock); // Maksimum stok değerinden fazla olamaz
                                } else {
                                  setProductCount(value);
                                }
                              }}
                            />

                            <a
                              href="#"
                              onClick={(e) => handlePlus(e, productData.stock)}
                            >
                              <FaPlus style={{ fill: "#000", color: "#000" }} />
                            </a>
                          </div>
                        </div>

                        {productData?.score > profileDetail?.score ? (
                          <Tooltip title="Üzgünüz, bu ürünü sipariş edebilmek için yeterli puanınız yok 😔">
                            <div className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-3 text-nowrap disabled">
                              <i className="fas fa-shopping-cart me-3 d-sm-inline-block d-none"></i>
                              Sepete Ekle
                            </div>
                          </Tooltip>
                        ) : (
                          <a
                            href="#"
                            className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-3 text-nowrap"
                            onClick={(e) => addToCart(e, productData.id)}
                          >
                            <i className="fas fa-shopping-cart me-3 d-sm-inline-block d-none"></i>
                            Sepete Ekle
                          </a>
                        )}
                      </div>
                    </div>

                    {productData.productCode && (
                      <div className="row row-cols-2 g-0 align-items-center small">
                        <div className="col py-3 border-bottom">Ürün Kodu</div>
                        <div className="col py-3 border-bottom">
                          {productData.productCode}
                        </div>
                      </div>
                    )}

                    {productData.brandStylePropertyDTOList?.length > 0 &&
                      productData.brandStylePropertyDTOList.map((item) => (
                        <div
                          className="row row-cols-2 g-0 align-items-center small"
                          key={item.id}
                        >
                          <div className="col py-3 border-bottom">
                            {item.propertyName}
                          </div>
                          <div className="col py-3 border-bottom">
                            {item.propertyValue}
                          </div>
                        </div>
                      ))}

                    {productData.description && (
                      <p className="mt-5 fw-light">{productData.description}</p>
                    )}
                  </div>
                </div>
              )}
              {/* End Product Content */}

              {/* Product Comments */}
              <div>
                <h2 className="text-center mb-sm-8 mb-5">Ürün Yorumları</h2>
                <div className="d-flex flex-column justify-content-center">
                  {productData?.productRateDTOList?.length > 0 ? (
                    productData.productRateDTOList.map((rate, j) => {
                      return (
                        <div
                          className="product-rate-comments mx-3 my-3"
                          key={j}
                        >
                          {rate.employeeImage && rate.employeeName ? (
                            <div className="wrapper-rate-comments-img">
                              <img
                                className="img-fluid rounded-circle"
                                src={rate.employeeImage}
                                alt={rate.employeeName}
                              />
                            </div>
                          ) : (
                            <div
                              className="wrapper-rate-comments-img"
                              style={{ background: "#000" }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/logo.svg"
                                }
                                alt=""
                                className="w-100 opacity-50"
                                style={{
                                  width: "300px",
                                  height: "200px",
                                  objectFit: "scale-down",
                                }}
                              />
                            </div>
                          )}

                          <div>
                            {rate?.commentDate && (
                              <p className="text-blue small mb-2 d-block">
                                {moment(rate?.commentDate).format("DD.MM.YYYY")}
                              </p>
                            )}

                            {rate.employeeName && <h6>{rate.employeeName}</h6>}

                            {rate.comment && <p>{rate.comment}</p>}

                            <div className="h4 text-gray m-0 p-0 d-inline-block fw-bold">
                              {rate.ratePoint >= 1 ? (
                                <FaStar className="text-yellow" />
                              ) : (
                                <FaStar />
                              )}

                              {rate.ratePoint >= 2 ? (
                                <FaStar className="text-yellow" />
                              ) : (
                                <FaStar />
                              )}

                              {rate.ratePoint >= 3 ? (
                                <FaStar className="text-yellow" />
                              ) : (
                                <FaStar />
                              )}

                              {rate.ratePoint >= 4 ? (
                                <FaStar className="text-yellow" />
                              ) : (
                                <FaStar />
                              )}

                              {rate.ratePoint === 5 ? (
                                <FaStar className="text-yellow" />
                              ) : (
                                <FaStar />
                              )}
                            </div>

                            {/* <div className="ps-2 m-0 d-inline-block">{parseFloat(rate.ratePoint).toFixed(1)}</div> */}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="m-0 text-center">
                      Henüz bir yorum yapılmadı.
                    </p>
                  )}
                </div>
              </div>
              {/* End Product Comments */}
            </div>
          </div>
        </section>

        {/* Other Products */}
        <section className="mb-sm-10 mb-5">
          <div className="container">
            <h2 className="text-center mb-sm-8 mb-5">Diğer Ürünler</h2>
            <div className="row g-sm-4 g-2">
              {productList && productList.totalElements > 0
                ? productList.content
                    .filter((item) => item.id !== id)
                    .slice(0, 4)
                    .map((item) => {
                      return (
                        <div className="col-xl-3 col-6" key={item.id}>
                          <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                            {item.productContentUrlList?.length > 0 &&
                            item.name ? (
                              <img
                                src={item.productContentUrlList[0]?.baseAddress}
                                alt={item.name}
                                className="img-contain w-100 p-3"
                                style={{ width: "300px", height: "200px" }}
                              />
                            ) : (
                              <div
                                style={{ background: "#000", width: "100%" }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/logo.svg"
                                  }
                                  alt=""
                                  className="w-100 opacity-50"
                                  style={{
                                    width: "300px",
                                    height: "200px",
                                    objectFit: "scale-down",
                                  }}
                                />
                              </div>
                            )}

                            <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                              {item.name && (
                                <h5 className="fw-normal lh-base mb-2">
                                  {item.name}
                                </h5>
                              )}

                              <span
                                className="d-block small text-black mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                              <NavLink
                                className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto"
                                to={{
                                  pathname: `/brand-product-detail/${item.brandSubCategoryId}/${item.id}`,
                                  brandStyleId: item.id,
                                }}
                              >
                                İncele
                                <i className="fas fa-arrow-right ms-3 d-sm-inline-block d-none" />
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      );
                    })
                : "Ürün Bulunmuyor!"}
            </div>
          </div>
        </section>
        {/* End Other Brands */}
      </main>

      <Footer />

      {showModal && (
        <Modal onClose={handleCloseEvaluateModal} isOpen={showModal}>
          <div className="evaluation">
            <div className="evaluation-content">
              <h3>Ürünü Puanlayın</h3>
              <div className="evaluation-content-rate">
                {selectedRate >= 5 ? (
                  <FaStar
                    className="text-yellow cursor-pointer"
                    onClick={() => setSelectedRate(5)}
                  />
                ) : (
                  <FaStar
                    className="cursor-pointer"
                    onClick={() => setSelectedRate(5)}
                  />
                )}

                {selectedRate >= 4 ? (
                  <FaStar
                    className="text-yellow cursor-pointer"
                    onClick={() => setSelectedRate(4)}
                  />
                ) : (
                  <FaStar
                    className="cursor-pointer"
                    onClick={() => setSelectedRate(4)}
                  />
                )}

                {selectedRate >= 3 ? (
                  <FaStar
                    className="text-yellow cursor-pointer"
                    onClick={() => setSelectedRate(3)}
                  />
                ) : (
                  <FaStar
                    className="cursor-pointer"
                    onClick={() => setSelectedRate(3)}
                  />
                )}

                {selectedRate >= 2 ? (
                  <FaStar
                    className="text-yellow cursor-pointer"
                    onClick={() => setSelectedRate(2)}
                  />
                ) : (
                  <FaStar
                    className="cursor-pointer"
                    onClick={() => setSelectedRate(2)}
                  />
                )}

                {selectedRate >= 1 ? (
                  <FaStar
                    className="text-yellow cursor-pointer"
                    onClick={() => setSelectedRate(1)}
                  />
                ) : (
                  <FaStar
                    className="cursor-pointer"
                    onClick={() => setSelectedRate(1)}
                  />
                )}
              </div>

              <div className="evaluation-content-comment">
                <h3>Ürün Hakkındaki Görüşleriniz</h3>
                <textarea
                  placeholder="Yorum"
                  className="form-control"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
                <a
                  href="#"
                  onClick={(e) => handleEvaluate(e)}
                  className="btn btn-success"
                >
                  Değerlendir
                </a>

                <div
                  className={
                    validationError ? "d-flex text-red mt-3" : "d-none"
                  }
                >
                  {errorMessage && errorMessage}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ShopDetail;

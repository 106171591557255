export const CREATE_ORDER_PENDING = "CREATE_ORDER_PENDING";
export const CREATE_ORDER_FULFILLED = "CREATE_ORDER_FULFILLED";
export const CREATE_ORDER_REJECTED = "CREATE_ORDER_REJECTED";

export const CANCEL_ORDER_PENDING = "CANCEL_ORDER_PENDING";
export const CANCEL_ORDER_FULFILLED = "CANCEL_ORDER_FULFILLED";
export const CANCEL_ORDER_REJECTED = "CANCEL_ORDER_REJECTED";

export const CANCEL_BRAND_ORDER_PENDING = "CANCEL_BRAND_ORDER_PENDING";
export const CANCEL_BRAND_ORDER_FULFILLED = "CANCEL_BRAND_ORDER_FULFILLED";
export const CANCEL_BRAND_ORDER_REJECTED = "CANCEL_BRAND_ORDER_REJECTED";

export const CONFIRM_PAYMENT_PENDING = "CONFIRM_PAYMENT_PENDING";
export const CONFIRM_PAYMENT_FULFILLED = "CONFIRM_PAYMENT_FULFILLED";
export const CONFIRM_PAYMENT_REJECTED = "CONFIRM_PAYMENT_REJECTED";

export const GET_SCORE_CURRENCY_PENDING = "GET_SCORE_CURRENCY_PENDING";
export const GET_SCORE_CURRENCY_FULFILLED = "GET_SCORE_CURRENCY_FULFILLED";
export const GET_SCORE_CURRENCY_REJECTED = "GET_SCORE_CURRENCY_REJECTED";

export const PAYMENT_ORDER_PENDING = "PAYMENT_ORDER_PENDING";
export const PAYMENT_ORDER_FULFILLED = "PAYMENT_ORDER_FULFILLED";
export const PAYMENT_ORDER_REJECTED = "PAYMENT_ORDER_REJECTED";

export const PAYMENT_FINISH_SESSION_PENDING = "PAYMENT_FINISH_SESSION_PENDING";
export const PAYMENT_FINISH_SESSION_FULFILLED =
  "PAYMENT_FINISH_SESSION_FULFILLED";
export const PAYMENT_FINISH_SESSION_REJECTED =
  "PAYMENT_FINISH_SESSION_REJECTED";

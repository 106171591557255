import * as authTypes from "./auth.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

// window.location.host
let siteURL = window.location.origin;

if (siteURL.startsWith("http://") && window.location.hostname === "localhost") {
  siteURL = siteURL.replace("http://", "https://");
}

export const login =
  ({ username, password }) =>
  async (dispatch) => {
    dispatch({ type: authTypes.LOGIN_PENDING });

    const data = {
      password,
    };

    try {
      const response = await API.post(
        `${REQUEST_URL}/auth/public/login`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Username": username,
          },
        }
      );

      if (response.data.changePass) {
        return (window.location.href = "/setNewPassword");
      }

      dispatch({ type: authTypes.LOGIN_FULFILLED, payload: response });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("expireSecond", response.data.expireSecond);
      // localStorage.setItem("userData", JSON.stringify(response.data));

      return Promise.resolve(response.data);
    } catch (error) {
      dispatch({ type: authTypes.LOGIN_REJECTED, payload: error });
      return Promise.reject(error);
    }
  };

function returnComplist(value) {
  return value;
}

export const setCompList = (value) => async (dispatch) => {
  dispatch({ type: authTypes.SET_COMPLIST_PENDING });

  try {
    const returnComponent = returnComplist(value);

    dispatch({
      type: authTypes.SET_COMPLIST_FULFILLED,
      payload: returnComponent,
    });
  } catch (error) {
    dispatch({
      type: authTypes.SET_COMPLIST_REJECTED,
      payload: error,
    });
  }
};

export const logout = (accessToken, refreshToken) => async (dispatch) => {
  const data = {
    accessToken: accessToken,
    refreshToken: refreshToken,
  };

  try {
    const response = await API.post(`${REQUEST_URL}/auth/public/logout`, data);

    dispatch({
      type: authTypes.LOGOUT_FULFILLED,
      payload: response,
    });

    localStorage.clear();

    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: authTypes.LOGOUT_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// Forgot Password
export const forgotPassword = (username, type) => async (dispatch) => {
  dispatch({ type: authTypes.FORGOT_PASSWORD_PENDING });

  const data = {
    username: username,
    type: type,
  };

  try {
    const response = await API.post(`/auth/public/forgotPassword`, data);

    dispatch({
      type: authTypes.FORGOT_PASSWORD_FULFILLED,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: authTypes.FORGOT_PASSWORD_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// Forgot Password Confirm
export const forgotPasswordConfirm = (code, username) => async (dispatch) => {
  dispatch({ type: authTypes.FORGOT_PASSWORD_CONFIRM_PENDING });

  const data = {
    confirmCode: code,
    username: username,
  };
  try {
    const response = await API.post(`/auth/public/confirm`, data);

    dispatch({
      type: authTypes.FORGOT_PASSWORD_CONFIRM_FULFILLED,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.FORGOT_PASSWORD_CONFIRM_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Catpcha
export const getCaptcha = () => async (dispatch) => {
  dispatch({ type: authTypes.GET_CAPTCHA_PENDING });

  try {
    const response = await API.get(`/auth/public/getCaptcha`);
    dispatch({
      type: authTypes.GET_CAPTCHA_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.GET_CAPTCHA_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const saveNewPassword =
  (newPass, newPassConfirm, username, confirmCode) => async (dispatch) => {
    dispatch({ type: authTypes.SAVE_NEW_PASSWORD_PENDING });

    const data = {
      newPass: newPass,
      newPassConfirm: newPassConfirm,
      tenantName: "tenant1",
      userName: username,
      confirmCode: confirmCode,
    };

    try {
      const response = await API.post(`/auth/saveNewPassword`, data);

      console.log("response", response);

      dispatch({
        type: authTypes.SAVE_NEW_PASSWORD_FULFILLED,
        payload: response.data,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({ type: authTypes.SAVE_NEW_PASSWORD_REJECTED, payload: error });
      return Promise.reject(error);
    }
  };

// Register
export const registerVendor = (formValues) => async (dispatch) => {
  dispatch({ type: authTypes.REGISTER_VENDOR_PENDING });

  const data = {
    tenantName: formValues.tenantName,
    name: formValues.name,
    surname: formValues.surname,
    mobileNo: formValues.mobileNo,
    email: formValues.email,
    username: formValues.username,
    vendorName: formValues.vendorName,
    salesDocumentNumber: formValues.salesDocumentNumber,
    salesDocumentType: formValues.salesDocumentType,
    password: formValues.password,
    passwordAgain: formValues.passwordAgain,
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/employee/public/register`,
      data
    );
    dispatch({ type: authTypes.REGISTER_VENDOR_FULFILLED, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: authTypes.REGISTER_VENDOR_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// Register Vendor Send Code
export const registerVendorSendCode = (username) => async (dispatch) => {
  dispatch({ type: authTypes.REGISTER_VENDOR_SEND_CODE_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/auth/public/saveCode/${username}`
    );
    dispatch({
      type: authTypes.REGISTER_VENDOR_SEND_CODE_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.REGISTER_VENDOR_SEND_CODE_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Register Confirm
export const registerVendorConfirm =
  (code, mobileNo, username) => async (dispatch) => {
    dispatch({ type: authTypes.REGISTER_VENDOR_CONFIRM_PENDING });

    const data = {
      confirmCode: code,
      userName: username,
      tenantName: "tenant1",
      mobileNo: mobileNo,
    };
    try {
      const response = await API.post(`/auth/public/confirmRegister`, data);

      dispatch({
        type: authTypes.REGISTER_VENDOR_CONFIRM_FULFILLED,
        payload: response.data,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({
        type: authTypes.REGISTER_VENDOR_CONFIRM_REJECTED,
        payload: error,
      });
      return Promise.reject(error);
    }
  };

export const confirmSmsCode = (code, mobileNo) => async (dispatch) => {
  dispatch({ type: authTypes.REGISTER_CONFIRM_SMS_CODE_PENDING });

  const data = {
    confirmCode: code,
    mobileNo: mobileNo,
  };
  try {
    const response = await API.post(`/employee/confirm/smsCode`, data);

    dispatch({
      type: authTypes.REGISTER_VENDOR_CONFIRM_FULFILLED,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.REGISTER_VENDOR_CONFIRM_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const returnNewScoreOfEmployee =
  (id, employeeId) => async (dispatch) => {
    dispatch({ type: authTypes.RETURN_EMPLOYEE_SCORE_PENDING });

    try {
      const response = await API.get(
        `${REQUEST_URL}/newScore/${id}/${employeeId}`
      );
      dispatch({
        type: authTypes.RETURN_EMPLOYEE_SCORE_FULFILLED,
        payload: response,
      });
      //    console.log("Response Data: New Score",response);
    } catch (error) {
      dispatch({
        type: authTypes.RETURN_EMPLOYEE_SCORE_REJECTED,
        payload: error,
      });
    }
  };

export const saveStatistics = () => async (dispatch) => {
  dispatch({ type: authTypes.SAVE_STATISTICS_PENDING });

  try {
    const response = await API.post(`/statistics/save/statistics`);
    dispatch({
      type: authTypes.SAVE_STATISTICS_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.SAVE_STATISTICS_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const refreshToken = (token, refreshToken) => async (dispatch) => {
  dispatch({ type: authTypes.REFRESH_TOKEN_PENDING });

  const data = {
    token: token,
    refreshToken: refreshToken,
  };

  try {
    const response = await API.post(`/auth/public/refresh`, data);
    dispatch({
      type: authTypes.REFRESH_TOKEN_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.REFRESH_TOKEN_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getLoginAnnouncement = () => async (dispatch) => {
  dispatch({ type: authTypes.GET_LOGIN_ANNOUCEMENT });

  try {
    const response = await API.get(
      `/announcement/public/loginPage/announcementList`
    );
    dispatch({
      type: authTypes.GET_LOGIN_ANNOUCEMENT,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.GET_LOGIN_ANNOUCEMENT,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getLoginSettings = (hostname) => async (dispatch) => {
  dispatch({ type: authTypes.LOGIN_SETTINGS_PENDING });

  try {
    const response = await API.get(
      `/auth/public/getLoginSetting?hostname=${hostname}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Host": siteURL,
        },
      }
    );
    dispatch({
      type: authTypes.LOGIN_SETTINGS_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.LOGIN_SETTINGS_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const setNewPassword = (saveNewPasswordDTO) => async (dispatch) => {
  dispatch({ type: authTypes.SET_NEW_PASSWORD_PENDING });

  try {
    const response = await API.post(
      `/auth/public/save/newPassword`,
      saveNewPasswordDTO
    );

    console.log("response", response);

    dispatch({
      type: authTypes.SET_NEW_PASSWORD_FULFILLED,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.SET_NEW_PASSWORD_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const getActiveComponents = () => async (dispatch) => {
  dispatch({ type: authTypes.ACTIVE_COMPONENTS_PENDING });

  try {
    const response = await API.get(`/management/get/active/componentList`);
    dispatch({
      type: authTypes.ACTIVE_COMPONENTS_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: authTypes.ACTIVE_COMPONENTS_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};
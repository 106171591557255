/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Home Components
import HomeAnnouncement from "./HomeAnnouncement";
import HomeProduct from "./HomeProduct";
import HomeLeaderboard from "./HomeLeaderboard";
import HomeSurvey from "./HomeSurvey";
import HomeGames from "./HomeGames";

const Home = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((store) => store.auth);
  const activeComponents = useSelector((store) => store.auth.activeComponents);
  const [showLeaderboard, setShowLeaderboard] = useState(false); // Başlangıçta gizli,

  // Active Component Check
  const isComponentActive = (componentName, componentList) => {
    // If componentName is not specified, always consider it active
    if (!componentName) return true;

    // componentList check
    if (!componentList || !activeComponents.length) return false;

    // Active component find
    return componentList.some(
      (item) => item.active && item.componentName === componentName
    );
  };

  const isComponentCategoryActive = (componentCategory, componentList) => {
    return componentList.some(
      (item) => item.active && item.componentCategory === componentCategory
    );
  };

  useEffect(() => {
    if (authUser) {
      dispatch(profileAction.getTenantMenu(authUser?.authUser?.roleId)).then(
        (res) => {
          if (res.isHttpSuccess && res.data && Array.isArray(res.data)) {
            if (res.data && Array.isArray(res.data)) {
              const tenantPaths = res?.data?.map((item) => item.menu?.pathUrl);

              if (!tenantPaths.includes("/leaderboard")) {
                setShowLeaderboard(true);
              } else {
                setShowLeaderboard(false);
              }
            }
          } else {
            setShowLeaderboard(true);
          }
        }
      );
    }
  }, [authUser]);

  return (
    <>
      <Header />
      {activeComponents?.length > 0 && isComponentActive("DUYURU", activeComponents) && <HomeAnnouncement />}
      {activeComponents?.length > 0 && isComponentActive("ÜRÜNLER", activeComponents) && <HomeProduct />}
      {activeComponents?.length > 0 && isComponentActive("LİDERLER TABLOSU", activeComponents) && showLeaderboard === true && <HomeLeaderboard />}
      {activeComponents?.length > 0 && isComponentActive("ANKET", activeComponents) && <HomeSurvey />}
      {activeComponents?.length > 0 && isComponentCategoryActive("OYUNLAR", activeComponents) && <HomeGames />}
      <Footer />
    </>
  );
};

export default Home;

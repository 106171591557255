import * as shopTypes from "./shop.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

// Get Shop Categories
export const getShopCategories = (pageType) => async (dispatch) => {
  dispatch({ type: shopTypes.GET_SHOP_CATEGORIES_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/catalog/category?pageType=${pageType}`
    );
    dispatch({
      type: shopTypes.GET_SHOP_CATEGORIES_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: shopTypes.GET_SHOP_CATEGORIES_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// Add To Cart
export const addToCart =
  (productId, productCount = 1) =>
  async (dispatch) => {
    dispatch({ type: shopTypes.ADD_TO_CART_PENDING });
    const data = {
      cartType: "EMPLOYEE",
      productCount: productCount,
      productId: productId,
    };

    try {
      const response = await API.post(`${REQUEST_URL}/cart/add/product`, data);
      dispatch({ type: shopTypes.ADD_TO_CART_FULFILLED, payload: response });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({ type: shopTypes.ADD_TO_CART_REJECTED, payload: error });
      return Promise.reject(error);
    }
  };

// Get Cart
export const getCart = () => async (dispatch) => {
  dispatch({ type: shopTypes.GET_CART_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/cart/get/cart`);
    dispatch({ type: shopTypes.GET_CART_FULFILLED, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: shopTypes.GET_CART_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// Get brand cart list
export const getBrandCartList = () => async (dispatch) => {
  dispatch({ type: shopTypes.GET_BRAND_CART_LIST_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/cart/get/brandStyle/cart`);
    dispatch({
      type: shopTypes.GET_BRAND_CART_LIST_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: shopTypes.GET_BRAND_CART_LIST_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";
import { homeAction } from "../../../redux/modules";
import { useDispatch, useSelector } from "react-redux";

// react-icons
import { FaUser } from "react-icons/fa";

const HomeLeaderboard = () => {
  const dispatch = useDispatch();

  const [leaderboard, setLeaderboard] = useState([]);

  const getLeaderboard = () => {
    dispatch(homeAction.getLeaderboard()).then((e) => {
      if (e.isHttpSuccess) {
        setLeaderboard(e.data.boardEmployeeDTOList);
      }
    });
  };

  useEffect(() => {
    getLeaderboard();
    return () => {};
  }, [dispatch]);

  const topThree = leaderboard?.slice(0, 3);

  const reorderedTopThree = [
    // Reorder the top three items to display them in the correct style
    topThree[1],
    topThree[0],
    topThree[2],
  ];

  const indexHelper = (index) => {
    if (index === 0) {
      return 2;
    } else if (index === 1) {
      return 1;
    } else if (index === 2) {
      return 3;
    }
  };

  const others = leaderboard?.slice(3, 9);

  // Helper function to group items into pairs
  const groupIntoPairs = (array) => {
    const pairs = [];
    for (let i = 0; i < array.length; i += 2) {
      pairs.push(array.slice(i, i + 2));
    }
    return pairs;
  };

  const othersInPairs = groupIntoPairs(others);

  return (
    <section className="leaderboard-section pb-sm-8 pb-5 mb-sm-10 mb-5">
      <Row className="justify-content-center">
        <Col xs={12} xl={8}>
          <div>
            <div className="leaderboard-header">
              <h3 className="leaderboard-title">LİDERLER TABLOSU</h3>
            </div>

            <div className="leaderboard-content">
              <div className="leaderboard-winners">
                {reorderedTopThree.map((item, index) => (
                  <div
                    key={index}
                    className={`winner-container winner-${indexHelper(index)}`}
                  >
                    {item?.icon ? (
                      <img
                        className="winner-image"
                        src={item?.icon}
                        alt="winner"
                      />
                    ) : (
                      <div
                        className="m-auto shadow-sm rounded-circle bg-gray d-flex flex-col justify-content-center align-items-center"
                        style={{ height: 48, width: 48 }}
                      >
                        <FaUser
                          className="text-gray-special"
                          style={{ width: 24, height: 24, color: "#fff" }}
                        />
                      </div>
                    )}
                    <p className="winner-name">{item?.employeeFullName}</p>
                    <p className="winner-points">{item?.value} Puan</p>
                  </div>
                ))}
              </div>

              <Row className="leaderboard-others">
                {othersInPairs.map((pair, pairIndex) => (
                  <Col key={pairIndex} xs={12} md={4}>
                    {pair.map((item, index) => (
                      <p key={index} className="other-rank">
                        {index + 4 + pairIndex * 2}. {item?.employeeFullName}
                      </p>
                    ))}
                  </Col>
                ))}
              </Row>
            </div>

            <div className="leaderboard-footer">
              <Link
                to="/leaderboard"
                className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3"
              >
                Tümünü Gör
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default HomeLeaderboard;

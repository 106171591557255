import * as orderTypes from "./order.type";

const initialState = {
  orderResponse: null,
  cancelOrder: null,
  scoreCurrency: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${orderTypes.CREATE_ORDER_PENDING}`:
    case `${orderTypes.CANCEL_ORDER_PENDING}`:
    case `${orderTypes.CANCEL_BRAND_ORDER_PENDING}`:
    case `${orderTypes.CONFIRM_PAYMENT_PENDING}`:
    case `${orderTypes.PAYMENT_FINISH_SESSION_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${orderTypes.CREATE_ORDER_FULFILLED}`:
    case `${orderTypes.PAYMENT_FINISH_SESSION_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        orderResponse: payload.data,
      };
    case `${orderTypes.CREATE_ORDER_REJECTED}`:
    case `${orderTypes.PAYMENT_FINISH_SESSION_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        orderResponse: null,
      };

    case `${orderTypes.CANCEL_ORDER_FULFILLED}`:
    case `${orderTypes.CANCEL_BRAND_ORDER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        cancelOrder: payload.data,
      };
    case `${orderTypes.CANCEL_ORDER_REJECTED}`:
    case `${orderTypes.CANCEL_BRAND_ORDER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        cancelOrder: null,
      };

    case `${orderTypes.GET_SCORE_CURRENCY_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        scoreCurrency: payload.data,
      };
    case `${orderTypes.GET_SCORE_CURRENCY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        cancelOrder: null,
      };
    case `${orderTypes.CONFIRM_PAYMENT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        orderResponse: payload.data,
      };
    case `${orderTypes.CONFIRM_PAYMENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        orderResponse: null,
      };

    default:
      return state;
  }
};

export default reducer;

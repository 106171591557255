import * as orderTypes from "./order.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

// Get Cart
export const createOrder =
  (
    cartType,
    categoryType,
    deliveryAddressId,
    description,
    isPaidCreditCard,
    isOrderForCatalog
  ) =>
  async (dispatch) => {
    dispatch({ type: orderTypes.CREATE_ORDER_PENDING });
    const data = {
      cartType: cartType,
      categoryType: categoryType,
      deliveryAddressId: deliveryAddressId,
      employeeFirmId: 1, // TODO: Will be remove
      description: description,
      isPaidCreditCard: isPaidCreditCard,
      isOrderForCatalog: isOrderForCatalog,
    };

    try {
      const response = await API.post(
        `${REQUEST_URL}/order/create/order`,
        data
      );
      dispatch({ type: orderTypes.CREATE_ORDER_FULFILLED, payload: response });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({ type: orderTypes.CREATE_ORDER_REJECTED, payload: error });
      return Promise.reject(error);
    }
  };

// Payment Order

export const paymentOrder =
  (deliveryAddressId, isOrderForBrand, description) => async (dispatch) => {
    dispatch({ type: orderTypes.PAYMENT_ORDER_PENDING });
    const data = {
      addressId: deliveryAddressId,
      isOrderForBrand: isOrderForBrand,
      description: description,
    };

    try {
      const response = await API.post(`${REQUEST_URL}/order/testPayment`, data);
      dispatch({ type: orderTypes.PAYMENT_ORDER_FULFILLED, payload: response });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({ type: orderTypes.PAYMENT_ORDER_REJECTED, payload: error });
      return Promise.reject(error);
    }
  };

// cancelOrder
export const cancelOrder = (orderId) => async (dispatch) => {
  dispatch({ type: orderTypes.CANCEL_ORDER_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/order/cancel/order/${orderId}`
    );
    dispatch({
      type: orderTypes.CANCEL_ORDER_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: orderTypes.CANCEL_ORDER_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// cancel brand order
export const cancelBrandOrder = (orderId) => async (dispatch) => {
  dispatch({ type: orderTypes.CANCEL_BRAND_ORDER_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/order/cancel/brand/order/${orderId}`
    );
    dispatch({
      type: orderTypes.CANCEL_BRAND_ORDER_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: orderTypes.CANCEL_BRAND_ORDER_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Get ScoreCurrency
export const getScoreCurrency = (usageType) => async (dispatch) => {
  dispatch({ type: orderTypes.GET_SCORE_CURRENCY_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/management/get/scoreCurrency/${usageType}`
    );
    dispatch({
      type: orderTypes.GET_SCORE_CURRENCY_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: orderTypes.GET_SCORE_CURRENCY_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Payment
export const confirmPayment = (addressId, isBrandOrder) => async (dispatch) => {
  dispatch({ type: orderTypes.CONFIRM_PAYMENT_PENDING });

  const data = {
    addressId: addressId,
    isBrandOrder: isBrandOrder,
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/order/payment/getSession`,
      data
    );
    dispatch({
      type: orderTypes.CONFIRM_PAYMENT_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: orderTypes.CONFIRM_PAYMENT_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// finish order session
export const paymentFinishSession =
  (token, isOrderCatalog) => async (dispatch) => {
    dispatch({ type: orderTypes.PAYMENT_FINISH_SESSION_PENDING });

    const data = {
      token: token,
      isOrderCatalog: isOrderCatalog,
    };

    try {
      const response = await API.post(
        `${REQUEST_URL}/order/payment/finishSession`,
        data
      );
      dispatch({
        type: orderTypes.PAYMENT_FINISH_SESSION_FULFILLED,
        payload: response,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({
        type: orderTypes.PAYMENT_FINISH_SESSION_REJECTED,
        payload: error,
      });
      return Promise.reject(error);
    }
  };
